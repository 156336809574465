<template>
    <div class="aside-fixed aside" :class="{ '--active': localIsMobileMenuOpen }">
        <router-link to="/" class="navbar__logo"></router-link>
        <div class="dashboard__routes" @click="toggleMobileMenu">
            <div v-if="isDashboardPage">
                <h1 class="dashboard__link">
                    <router-link :to="{ name: 'dashboard.timeline.times' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'stream']" />
                            {{ $t('dashboard.timeline') }}
                        </div>
                    </router-link>
                </h1>
                <h1 v-if="haveAcess" class="dashboard__link">
                    <router-link :to="{ name: 'dashboard.team' }">
                        <div class="test">
                            <font-awesome-icon :icon="['fas', 'users']" />
                        </div>
                        <div class="icons">
                            {{ $t('dashboard.team') }}
                        </div>
                    </router-link>
                </h1>

                <h1 class="dashboard__link">
                    <router-link :to="{ name: 'report.projects' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'file-invoice']" />
                            {{ $t('navigation.project-report') }}
                        </div>
                    </router-link>
                </h1>
                <h1 class="dashboard__link">
                    <router-link :to="{ name: 'report.time-use' }">
                        <div class="test">
                            <font-awesome-icon :icon="['fas', 'clock']" />
                        </div>
                        <div class="icons">
                            {{ $t('navigation.time-use-report') }}
                        </div>
                    </router-link>
                </h1>
            </div>
            <div v-if="isInvoicePage">
                <h1 class="dashboard__link">
                    <router-link :to="{ name: 'invoice.upload' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'external-link-alt']" />
                            {{ $t('navigation.invoice.external') }}
                        </div>
                    </router-link>
                </h1>
                <h1 class="dashboard__link">
                    <router-link :to="{ name: 'invoice.outgoing' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'chart-line']" />
                            {{ $t('navigation.invoice.outgoing') }}
                        </div>
                    </router-link>
                </h1>
            </div>
            <div v-if="isProjectPage">
                <h1 class="dashboard__link">
                    <router-link :to="{ path: '/projects' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'folder']" />
                            {{ $t('navigation.projects') }}
                        </div>
                    </router-link>
                </h1>
                <h1 class="dashboard__link">
                    <router-link :to="{ path: '/tasks' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'tasks']" />
                            {{ $t('navigation.tasks') }}
                        </div>
                    </router-link>
                </h1>
                <h1 v-if="canAccessKanban" class="dashboard__link">
                    <router-link
                        :to="{ path: '/kanban/board' }"
                        :class="{ 'router-link-exact-active router-link-active': $route.path.includes('kanban') }"
                    >
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'columns']" />
                            {{ $t('navigation.kanbanBoard') }}
                        </div>
                    </router-link>
                </h1>
                <h1 v-if="canAccessSprint" class="dashboard__link">
                    <router-link :to="{ path: '/sprints' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'running']" />
                            {{ $t('navigation.sprints') }}
                        </div>
                    </router-link>
                </h1>
            </div>
            <div v-if="isClientsPage">
                <h1 class="dashboard__link">
                    <router-link :to="{ path: `/client/view/${this.$route.params.id}` }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'info-circle']" />
                            {{ $t('documents.info') }}
                        </div></router-link
                    >
                </h1>
                <h1 class="dashboard__link">
                    <div class="documents"></div>
                    <router-link :to="{ name: 'document.list' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'file-alt']" />
                            {{ $t('documents.name') }}
                        </div>
                    </router-link>
                </h1>
                <h1 class="dashboard__link">
                    <router-link :to="{ name: 'client-services.list' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'file']" />
                            {{ $t('documents.services') }}
                        </div>
                    </router-link>
                </h1>
                <h1 class="dashboard__link">
                    <router-link :to="{ name: 'client.users.list' }">
                        <div class="icons">
                            <font-awesome-icon :icon="['fas', 'users']" />
                            {{ $t('client_users.title') }}
                        </div>
                    </router-link>
                </h1>
            </div>
        </div>
        <div class="left-nav-mobile" @click="toggleMobileMenu">
            <h4>Manage</h4>
            <div v-if="user" class="nav-items">
                <template v-for="(item, key) in navItems">
                    <navigation-menu-item
                        :key="key"
                        :to="item.to || undefined"
                        toggleMobileMenu
                        @click="item.click || undefined"
                    >
                        {{ $t(item.label) }}
                    </navigation-menu-item>
                </template>
            </div>
        </div>
        <div
            class="git-versions"
            style="position: fixed; bottom: 0; left: 0; padding: 10px; background: rgba(0, 0, 0, 0.5); color: white"
        >
            <p>F: {{ frontendVersion }}</p>
            <p>B: {{ backendVersion }}</p>
        </div>
    </div>
</template>

<script>
    import DashboardPolicy from '_internal/Dashboard/policies/dashboard.policy';
    import ApiService from '@/services/api';
    import NavigationMenuItem from '@/components/NavigationMenuItem.vue';
    import SubscriptionPolicy from '../policies/subscription.policy';
    import RolePolicy from '../policies/role.policy';
    import SprintModule from '../modules/sprints/module.init';
    import KanbanModule from '../modules/kanban/module.init';

    export default {
        name: 'Index',
        components: { NavigationMenuItem },
        props: {
            isMobileMenuOpen: {
                type: Boolean,
                default: false,
            },
            modules: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                user: null,
                showNavigationList: true,
                localIsMobileMenuOpen: this.isMobileMenuOpen,
                backendVersion: null,
                frontendVersion: null,
            };
        },
        created() {
            this.fetchUser();
            this.fetchBackendVersion();
            this.fetchFrontendVersion();
        },
        methods: {
            toggleMobileMenu() {
                this.localIsMobileMenuOpen = !this.localIsMobileMenuOpen;
                this.$emit('update:is-mobile-menu-open', this.localIsMobileMenuOpen);
                const overlay = document.querySelector('.drawer-overlay-mobile');
                if (overlay.classList.contains('--active-overlay')) {
                    overlay.classList.remove('--active-overlay');
                }
            },
            userDropdownHandle(route) {
                this.$router.push({ name: route });
            },
            async fetchUser() {
                try {
                    const apiService = new ApiService(this.$store);
                    await apiService.checkApiAuth();
                    this.user = JSON.parse(localStorage.getItem('user'));
                } catch (error) {
                    console.error(error);
                }
            },
            async fetchBackendVersion() {
                try {
                    const apiService = new ApiService(this.$store);
                    const version = await apiService.backendVersion();
                    this.backendVersion = version;
                } catch (error) {
                    console.error(error);
                }
            },
            fetchFrontendVersion() {
                this.frontendVersion = process.env.VUE_APP_GIT_HASH
                    ? process.env.VUE_APP_GIT_HASH
                    : process.env.VUE_APP_GIT_FRONT_HASH;
            },
        },
        computed: {
            canAccessKanban() {
                if (KanbanModule) {
                    return (
                        SubscriptionPolicy.viewTab(KanbanModule.SUBSCRIPTION_PLAN) &&
                        RolePolicy.haveRole(KanbanModule.ROLES_ACCESS)
                    );
                }
                return false;
            },
            canAccessSprint() {
                if (SprintModule) {
                    return (
                        SubscriptionPolicy.viewTab(SprintModule.SUBSCRIPTION_PLAN) &&
                        RolePolicy.haveRole(SprintModule.ROLES_ACCESS)
                    );
                }
                return false;
            },
            navItems() {
                const navItems = [];
                this.modules.forEach(m => {
                    const entries = m.getNavbarEntries();
                    entries.forEach(e => {
                        if (e.displayCondition(this.$store)) {
                            navItems.push(e.getData());
                        }
                    });
                });

                return navItems;
            },
            navDropdowns() {
                const entriesDr = {};
                this.modules.forEach(m => {
                    const entriesDropdown = m.getNavbarEntriesDropdown();
                    Object.keys(entriesDropdown).forEach(key => {
                        const isAllowItem = entriesDropdown[key][0].displayCondition(this.$store);
                        if (!entriesDr.hasOwnProperty(entriesDropdown[key][0].section) && isAllowItem) {
                            entriesDr[entriesDropdown[key][0].section] = [];
                        }
                        if (isAllowItem) {
                            entriesDr[entriesDropdown[key][0].section].push(entriesDropdown[key][0]);
                        }
                    });
                });

                return entriesDr;
            },
            canViewTeamTab() {
                return DashboardPolicy.viewTeamTab(this.user);
            },
            windowWidth() {
                return window.innerWidth;
            },
            haveAcess() {
                if (!this.user) {
                    return false;
                }
                return this.user.roles_access.is_admin || false;
            },
            isInvoicePage() {
                return this.$route.path.includes('invoice');
            },
            isProjectPage() {
                return (
                    this.$route.path === '/projects' ||
                    this.$route.path.includes('tasks') ||
                    this.$route.path.includes('kanban') ||
                    this.$route.path.includes('sprints')
                );
            },
            isClientsPage() {
                if (this.$route.path.includes('company/documents/')) {
                    return false;
                }
                return (
                    this.$route.path.includes('/client/view') ||
                    this.$route.path.includes('/document') ||
                    this.$route.path.includes('/client-services/') ||
                    this.$route.path.includes('/client-users')
                );
            },
            isDashboardPage() {
                return (
                    this.$route.path.includes('dashboard') ||
                    this.$route.path.includes('report/projects') ||
                    this.$route.path.includes('report/time-use')
                );
            },
        },
        watch: {
            windowWidth(newWidth) {
                this.showNavigationList = newWidth > 992;
            },
            isMobileMenuOpen(newVal) {
                this.localIsMobileMenuOpen = newVal;
                this.$emit('update:is-mobile-menu-open', newVal);
                if (newVal) {
                    document.body.style.overflow = 'hidden';
                    document.querySelector('.drawer-overlay-mobile ').classList.add('--active-overlay');
                } else {
                    document.body.style.overflow = 'auto';
                    document.querySelector('.drawer-overlay-mobile ').classList.remove('--active-overlay');
                }
            },
        },
    };
</script>
<style scoped lang="scss">
    .git-versions {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px;
        background: #fff !important;
        .theme-dark & {
            background: #090a0d !important;
        }
    }

    .divider {
        margin: 20px 0;
        border: none;
        border-top: 1px solid #ddd;

        .theme-dark & {
            border-color: #555;
        }
    }
    .test {
        font-size: 24px;
    }
</style>
