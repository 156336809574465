import { render, staticRenderFns } from "./PrioritySelect.vue?vue&type=template&id=27fb8e32&scoped=true"
import script from "./PrioritySelect.vue?vue&type=script&lang=js"
export * from "./PrioritySelect.vue?vue&type=script&lang=js"
import style0 from "./PrioritySelect.vue?vue&type=style&index=0&id=27fb8e32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27fb8e32",
  null
  
)

export default component.exports