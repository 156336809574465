<template>
    <at-dropdown placement="bottom-right">
        <div class="at-menu__submenu-title current-company">
            <i v-if="companies.length != 0" class="icon-companies">
                <img src="../icon/companies.svg" alt="companies" />
            </i>
            {{ currentCompanyName }}
        </div>
        <at-dropdown-menu slot="menu">
            <div v-for="(item, index) in companies" :key="index">
                <li class="at-dropdown-menu__item" @click="changeCompany(item.attributes.id)">
                    <i class="icon icon-log-out"></i> {{ item.attributes.name }}
                </li>
            </div>
        </at-dropdown-menu>
    </at-dropdown>
</template>

<script>
    import CompanyService from '@/services/company.service';
    export default {
        data() {
            return {
                service: new CompanyService(),
            };
        },

        props: {
            user: {
                type: Object,
                required: true,
            },
        },
        computed: {
            companies() {
                if (this.user.companies != undefined) {
                    return this.user.companies.filter(company => company.attributes.id != this.user.current_company_id);
                }
                return [];
            },

            currentCompanyName() {
                if (this.user.companies != undefined) {
                    const current_company = this.user.companies.find(
                        company => company.attributes.id === this.user.current_company_id,
                    );
                    return this.truncate(current_company.attributes.name);
                }
                return '';
            },

            companyLength() {
                if (this.companies) {
                    return this.companies.length;
                }
                return 1;
            },
        },
        methods: {
            async changeCompany(company_id) {
                let formData = new FormData();
                formData.append('id', this.user.id);
                formData.append('company_id', company_id);

                try {
                    await this.service.update(formData);
                    localStorage.company = null;
                    this.$store.dispatch('company/setCompanyData', null);
                    location.reload(true);
                } catch ({ response }) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request is canceled');
                    }
                }
            },

            truncate(value) {
                const companyLength = 20;
                return value.length >= companyLength ? value.substring(0, companyLength) + '...' : value;
            },
        },
    };
</script>
