<template>
    <div class="language-select">
        <at-select
            v-if="Object.keys(languages).length > 0"
            ref="select"
            :value="value"
            clearable="clearable"
            @on-change="inputHandler($event)"
        >
            <at-option v-for="(lang, index) in languages" :key="index" :value="lang.value" :label="lang.label">
                <span class="option">
                    <span class="option-text">
                        {{ lang.label }}
                    </span>
                </span>
            </at-option>
        </at-select>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        props: {
            value: {
                type: [Number, String],
                required: true,
            },
        },
        computed: {
            ...mapGetters('lang', ['langList']),

            languages() {
                return Object.keys(this.langList).map(p => ({
                    value: p,
                    label: this.langList[p],
                }));
            },
        },
        methods: {
            inputHandler(ev) {
                this.$emit('setLanguage', ev);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .language-select {
        border-radius: 5px;

        &::v-deep .at-select__selection {
            background: transparent;
            .theme-dark & {
                border-color: #555;
                background-color: #555;
            }
        }

        &::v-deep .at-select__dropdown .at-select__option {
            padding: 0;
        }

        &::v-deep .at-select {
            color: inherit;
        }
    }

    .option {
        display: block;
        width: 100%;
        padding: 6px 12px;
        .theme-dark & {
            background-color: #555;
            color: #c4c4cf;
            &:hover {
                color: #ffa500;
                background-color: #333;
            }
        }
    }
</style>
