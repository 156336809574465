<template>
    <at-select ref="select" v-model="model" multiple filterable @input="onChange">
        <at-option
            v-for="(role, index) in company_roles"
            :key="index"
            :value="role.attributes.id"
            :label="capitalize(role.attributes.name)"
        >
            <div>{{ capitalize(role.attributes.name) }}</div>
            <div class="role-select__description">
                <slot v-if="role.attributes.description" :name="role.attributes.name">
                    {{ role.attributes.description }}
                </slot>
            </div>
        </at-option>
    </at-select>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props: {
            selected: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                model: [],
                showCount: true,
                options: [],
            };
        },

        computed: {
            ...mapGetters('roles', ['company_roles']),
        },
        methods: {
            ...mapActions('roles', ['loadCompanyRoles']),

            onChange(val) {
                if (this.inputHandler) {
                    this.inputHandler(val);
                }
            },

            inputHandler(value) {
                this.$emit('input', value);
                this.$emit('updateProps', value);
            },

            async fetchRoles() {
                await this.loadCompanyRoles();
            },

            capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
        },
        async created() {
            await this.fetchRoles();

            this.model = this.selected;
        },
    };
</script>

<style lang="scss" scoped>
    .role-select {
        &__description {
            white-space: normal;
            opacity: 0.6;
            font-size: 0.7rem;
        }
    }
</style>
