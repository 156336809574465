<template>
    <div>
        <at-select
            ref="select"
            v-model="selectedCompanies"
            multiple
            filterable
            placeholder="Select companies"
            @input="onSelectionChange"
        >
            <at-option
                v-for="(company, index) in companies"
                :key="index"
                :value="company.company_id"
                :label="capitalize(company.name)"
            >
                <div>{{ capitalize(company.name) }}</div>
                <div class="company-select__description">
                    <slot v-if="company.description" :name="company.name">
                        {{ company.description }}
                    </slot>
                </div>
            </at-option>
        </at-select>
    </div>
</template>

<script>
    import ClientService from '@/modules/Client/services/client.service';

    export default {
        props: {
            companiesIDs: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                service: new ClientService(),
                companies: null,
                selectedCompanies: [],
            };
        },
        async created() {
            await this.fetchClientsCompanies();
            if (this.companiesIDs.length != 0) {
                this.selectedCompanies = this.companiesIDs;
            }
        },
        methods: {
            async fetchClientsCompanies() {
                try {
                    const { data } = await this.service.getWithFilters({ disable_pagy: true });

                    this.companies = data.data
                        .filter(item => item.attributes.name !== null)
                        .map(item => ({
                            id: item.id,
                            name: item.attributes.name,
                            company_id: item.attributes.company_id,
                        }));
                } catch (error) {
                    console.error('Error fetching client companies:', error);
                }
            },
            onSelectionChange(selected) {
                this.$emit('change', selected);
            },
            capitalize(string) {
                if (!string) return '';
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
        },
    };
</script>
