<template>
    <div class="notification">
        <div class="icon-wrapper" @click="toggleDropdown">
            <i class="fas fa-bell"></i>
            <div class="bell">
                <font-awesome-icon icon="bell" />
            </div>
            <span v-if="unreadCount" class="notification-count">{{ unreadCount }}</span>
        </div>
        <div v-if="dropdownOpen" class="dropdown" @scroll="handleScroll">
            <h3>{{ $t('notifications.title') }}</h3>
            <div v-if="notifications.length > 0">
                <ul>
                    <li
                        v-for="notification in sortedNotifications"
                        :key="notification.id"
                        :class="{ unread: !notification.attributes.readed }"
                        @click="markAsRead(notification.id)"
                    >
                        <div class="notification-content">
                            {{ notification.attributes.message }}
                            <div
                                v-if="notification.attributes.target.attributes.object_approval_status == 'pending'"
                                class="action-buttons"
                            >
                                <button
                                    class="at-btn crud__control-items__item at-btn--primary at-btn--large"
                                    @click="approve(notification.attributes)"
                                >
                                    {{ $t('notifications.approve') }}
                                </button>
                                <button
                                    class="at-btn at-btn--error at-btn--large"
                                    @click="reject(notification.attributes)"
                                >
                                    {{ $t('notifications.reject') }}
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else class="no-notifications">
                <p>{{ $t('notifications.noNotifications') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import NotificationService from '../services/notificaion.service';
    import { mapGetters } from 'vuex';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        data() {
            return {
                notifications: [],
                dropdownOpen: false,
                service: new NotificationService(),
                query: { page: 1 },
                loading: false,
                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
            unreadCount() {
                return this.notifications.filter(n => !n.attributes.readed).length;
            },
            sortedNotifications() {
                return [...this.notifications].sort((a, b) => {
                    return a.attributes.readed - b.attributes.readed;
                });
            },
        },
        async created() {
            document.addEventListener('click', this.handleClickOutside);
            await this.fetchNotifications();
            this.connectWebSocket();
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
        methods: {
            toggleDropdown(event) {
                event.stopPropagation();
                this.dropdownOpen = !this.dropdownOpen;
            },
            handleClickOutside(event) {
                const dropdown = this.$el.querySelector('.dropdown');
                if (dropdown && !dropdown.contains(event.target)) {
                    this.dropdownOpen = false;
                }
            },
            async fetchNotifications() {
                if (this.loading) return;
                this.loading = true;
                try {
                    const res = await this.service.getAll({ page: this.query.page });
                    const { data } = res.data;
                    if (data.length > 0) {
                        this.notifications = [...this.notifications, ...data];
                        this.query.page += 1;
                    }
                } catch (error) {
                    console.error('Error fetching notifications', error);
                } finally {
                    this.loading = false;
                }
            },
            async approve(notification) {
                let formData = new FormData();
                formData.append('approvals_object[object_model_name]', notification.target.attributes.notifiable_type);
                formData.append('approvals_object[object_id]', notification.target.attributes.notifiable_id);

                await this.service.approve(formData);
                this.markAsRead(notification.id);
            },

            async reject(notification) {
                let formData = new FormData();
                formData.append('approvals_object[object_model_name]', notification.target.attributes.notifiable_type);
                formData.append('approvals_object[object_id]', notification.target.attributes.notifiable_id);

                await this.service.reject(formData);
                this.markAsRead(notification.id);
            },
            async markAsRead(notificationId) {
                try {
                    const notification = this.notifications.find(n => n.id === notificationId);
                    if (!notification || notification.attributes.readed) return;

                    notification.attributes.readed = true;

                    await this.service.update(notificationId, { notification: { readed: true } });
                } catch (error) {
                    console.error('Error marking notification as read', error);
                }
            },
            handleScroll(event) {
                const dropdown = event.target;
                if (
                    dropdown.scrollTop + dropdown.clientHeight >= dropdown.scrollHeight - 10 &&
                    this.notifications.length > 10
                ) {
                    console.log('Fetching more notifications...');
                    this.fetchNotifications();
                }
            },
            connectWebSocket() {
                if (!this.wsUrl) return;
                if (!this.company) return;

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subNotification = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'NotificationChannel',
                            company_id: this.company.id,
                        }),
                    };
                    this.ws.send(JSON.stringify(subNotification));
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, notification } = data.message;

                        const actions = {
                            new_notification: () => this.wsAddNewNotification(notification.data),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    console.log('WebSocket connection closed');
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    console.error('WebSocket error:', error);
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    console.log(`Try connecting (${retries}/${maxRetries})...`);
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    console.log('The maximum number of reconnections has been reached.');
                }
            },

            wsAddNewNotification(data) {
                this.notifications.push(data);
            },
        },
    };
</script>

<style scoped lang="scss">
    .notification {
        position: relative;
        display: inline-block;
        margin-right: 10px;
        font-family: 'Arial', sans-serif;
    }

    .icon-wrapper {
        position: relative;
        cursor: pointer;
        font-size: 1.5rem;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;

        &:hover {
            color: #ffa500;
        }

        .notification-count {
            position: absolute;
            top: -5px;
            right: -5px;
            background-color: #ff3b3b;
            color: #fff;
            font-size: 0.75rem;
            font-weight: bold;
            border-radius: 50%;
            padding: 4px 7px;
            line-height: 1;
            text-align: center;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            animation: pulse 1.5s infinite;
        }

        @keyframes pulse {
            0%,
            100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
        }
    }

    .dropdown {
        position: absolute;
        top: 60px;
        right: 0;
        width: 320px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
        border: 1px solid #e0e0e0;
        overflow: hidden;
        z-index: 10;

        .theme-dark & {
            background-color: #2d2d2d;
            border-color: #444;
        }

        h3 {
            margin: 0;
            padding: 16px;
            font-size: 1.2rem;
            font-weight: 600;
            color: #333333;
            background-color: #f5f5f5;
            border-bottom: 1px solid #e0e0e0;
            text-align: center;

            .theme-dark & {
                color: #eaeaea;
                background-color: #3d3d3d;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        li {
            padding: 12px 16px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            border-bottom: 1px solid #f0f0f0;
            color: #666666;
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.2s ease;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: #f9f9f9;
                transform: scale(1.02);

                .theme-dark & {
                    background-color: #454545;
                }
            }

            &.unread {
                font-weight: bold;
                color: #333333;

                .theme-dark & {
                    color: #eaeaea;
                }
            }

            .notification-content {
                font-size: 0.9rem;
                line-height: 1.4;
            }

            .action-buttons {
                margin-top: 8px;
                display: flex;
                gap: 10px;
            }
        }

        .no-notifications {
            text-align: center;
            padding: 20px;
            color: #aaa;
            font-style: italic;
        }
    }
</style>
