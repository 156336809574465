<template>
    <div v-if="visible" class="modal">
        <div class="modal-content">
            <span class="close" @click="close">&times;</span>
            <p>{{ message }}</p>
            <button class="confirm-button" @click="confirm">Yes</button>
            <button class="cancel-button" @click="close">No</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
            message: {
                type: String,
                default: '',
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },
            confirm() {
                this.$emit('confirm');
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 10001;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .modal-content {
        background-color: #fefefe;
        padding: 20px;
        border: 1px solid #888;
        width: 300px;
        border-radius: 8px;
        text-align: center;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }

    .confirm-button,
    .cancel-button {
        margin: 10px;
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        border: none;
        border-radius: 4px;
    }

    .confirm-button {
        background-color: #dc3545;
        color: white;
    }

    .confirm-button:hover {
        background-color: #c82333;
    }

    .cancel-button {
        background-color: #6c757d;
        color: white;
    }

    .cancel-button:hover {
        background-color: #5a6268;
    }

    p {
        .theme-dark & {
            color: #ffa500;
        }
    }
</style>
