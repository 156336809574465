<template>
    <at-dropdown placement="bottom-right">
        <div class="at-menu__submenu-title theme-class">
            <span :class="['theme-icon', 'icon-' + currentTheme]" alt="Theme Icon"></span>
        </div>
        <at-dropdown-menu slot="menu">
            <div>
                <li
                    v-for="(theme, index) in themes"
                    :key="index"
                    :class="{ 'at-dropdown-menu__item switcher-class': true, active: theme.value === currentTheme }"
                    @click="setTheme(theme.value)"
                >
                    <span :class="['theme-icon', 'icon-' + theme.value]"></span>
                    {{ $t(`theme.${theme.value}`) }}
                </li>
            </div>
        </at-dropdown-menu>
    </at-dropdown>
</template>

<script>
    export default {
        data() {
            return {
                currentTheme: 'system',
                themes: [
                    { label: 'Light', value: 'light' },
                    { label: 'Dark', value: 'dark' },
                    { label: 'System', value: 'system' },
                ],
            };
        },
        mounted() {
            const storedTheme = localStorage.getItem('theme');
            if (storedTheme) {
                this.currentTheme = storedTheme;
                this.applyTheme(storedTheme);
            } else {
                this.applySystemTheme();
            }
        },
        methods: {
            setTheme(theme) {
                this.currentTheme = theme;
                this.applyTheme(theme);
                localStorage.setItem('theme', theme);
                this.$store.dispatch('dashboard/updateTheme', theme);
            },
            applyTheme(theme) {
                const themeClass =
                    theme === 'system'
                        ? window.matchMedia('(prefers-color-scheme: dark)').matches
                            ? 'dark'
                            : 'light'
                        : theme;
                document.documentElement.classList.remove('theme-light', 'theme-dark');
                document.documentElement.classList.add(`theme-${themeClass}`);
            },
            applySystemTheme() {
                const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
                const systemTheme = isDarkMode ? 'dark' : 'light';
                this.applyTheme(systemTheme);
            },
        },
    };
</script>

<style lang="scss">
    .theme-icon {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        margin-bottom: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &.icon-light {
            background-image: url('../icon/brightness-contrast.svg');
        }

        &.icon-dark {
            background-image: url('../icon/moon-o.svg');
        }

        &.icon-system {
            background-image: url('../icon/display.svg');
        }
    }

    .navbar .at-dropdown__popover {
        .theme-icon {
            width: 15px;
            height: 15px;
            margin-right: 0px;
            margin-bottom: 1px;
        }
    }
</style>
